import {Link} from "./Link";
import {LoggedOut} from "../lib/auth/components/LoggedOut";
import {LoggedIn} from "../lib/auth/components/LoggedIn";
import {LogoutButton} from "./Header/LogoutButton";

export function Header() {
    return (
        <header className={'border-b border-slate-200 -mx-4 -mt-4 px-8 py-4 flex justify-between items-center hover:shadow transition-shadow'}>
            <ul className={'flex gap-4 items-center'}>
                <li><Link href={'/'}>WT</Link></li>
                <LoggedIn>
                    <li><Link href={'/dashboard'}>Dashboard</Link></li>
                    {/*<li><Link href={'/calories'}>Calorie tracker</Link></li>*/}
                </LoggedIn>
            </ul>
            <ul className={'flex gap-4 items-center'}>
                <LoggedOut>
                    <li><Link href={'/login'}>Login</Link></li>
                    <li><Link href={'/register'}>Register</Link></li>
                </LoggedOut>
                <LoggedIn>
                    <li><Link href={'/profile'}>Profile</Link></li>
                    <li><LogoutButton/></li>
                </LoggedIn>
            </ul>
        </header>
    )
}
